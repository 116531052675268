document.addEventListener('DOMContentLoaded', () => {
    function iframeClose(popup) {
        const iframe = popup.querySelector('iframe');
        if (iframe) {
            iframe.src = ''
        }
    }
    function popupClose(popup) {
        popup.classList.remove('open','full-screen');
        iframeClose(popup)
    }

    const popupList = document.querySelectorAll('.popup');

    if (popupList) {
        popupList.forEach(popup => {
            const popupCloseBtnList = document.querySelectorAll('.js-popup-close');
            const popupIframeCloseBtn = document.querySelector('.js-iframe-close');
            const popupIframeFullScreen = document.querySelector('.js-iframe-full-screen');
            popupCloseBtnList.forEach(popupCloseBtn => {
                popupCloseBtn.addEventListener('click', () => {
                    popupClose(popup)
                })
            })
            popupIframeFullScreen.addEventListener('click', () => {
                popup.classList.add('full-screen');
            })
        })
    }

    const btnIframeList = document.querySelectorAll('.js-btn-iframe')

    if (btnIframeList) {
        const popupIframe = document.querySelector('.popup--iframe')
        btnIframeList.forEach(btnIframe => {
            btnIframe.addEventListener('click', (event) => {
                popupIframe.classList.add('open')
                const iframe = popupIframe.querySelector('iframe');
                iframe.src = event.target.getAttribute('data-url');
                const popupTitle = popupIframe.querySelector('.popup__title');
                popupTitle.innerText = event.target.getAttribute('data-title');
            })
        })
    }
})